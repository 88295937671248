import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dropdownmenu',
  templateUrl: './dropdownmenu.component.html',
  styleUrls: ['./dropdownmenu.component.scss']
})
export class DropDownMenuComponent implements OnInit {
  @Input() fab : boolean = false;
  @Input() color : string = 'primary';
  @Input() menuIcon : string = 'more_vert';
  @Input() menuLabel : string = null;
  @Input() items : any[] = [];
  @Input() itemId : any = null;
  @Input() row : any = null;

  @Output() itemEvent: EventEmitter<any> = new EventEmitter()


  constructor(

  ) { }

  ngOnInit() {
  }

  onItemClick(item, event) {
    if(item.callback) {
      item.callback(this.row.id, event)
    } else if(item.event) {
      this.itemEvent.emit(item.event);
    } else if(item.click) {
      item.click();
    }
  }

  onItemEvent(event) {
    this.itemEvent.emit(event);
  }
}
