import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss'],
})

export class MessageForm {
  public form: FormGroup;
  @Input() showTitle: boolean = true;
  @Input() showBtn: boolean = true;
  @Input() save: boolean = false;
  @Input() reset: boolean = false;
  @Output() dataForm = new EventEmitter();
  @Output() closeForm = new EventEmitter();
  
  public editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }],
    ]
  };

  constructor(
    private translate: TranslateService,
    public dataService: DataService) {

    // create formgroup
    this.form = new FormGroup(
      {
        message: new FormControl('', [Validators.required]),
        title: new FormControl(null),
        thread_id: new FormControl(null),
        from_user_id: new FormControl(null),
        to_user_id: new FormControl(null),
      },
      {
        updateOn: 'change',
      }
    );
  }

  saveData() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dataForm.emit(this.form.value);
    } else {
      console.log('form is not valid');
    }
  }

  btnCancel() {
    this.closeForm.emit(true);
  }

  ngOnChanges(){
    if(this.reset){
      this.form.reset();
    }
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    let field = this.form.controls[controlName];
    return field.hasError(errorName) && field.touched;
  }

}