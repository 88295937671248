import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash';
import * as moment from 'moment';
//import 'moment/locale/fr';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthUser } from './../models/AuthUser';
import { User } from './../models/User';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public authenticated = false;
  protected api_root = environment.api.root;
  public isLoading: boolean = false;
  private currentAuthUserSubject: BehaviorSubject<AuthUser>;
  public currentAuthUser: Observable<AuthUser>;
  private tokenRefreshedSubject: BehaviorSubject<String>;
  public tokenRefreshed: Observable<String>;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private http: HttpClient;
  private _tokenInterval = null;
  private _tokenIntervalDuration = 60; // duration in seconds

  constructor(private handler: HttpBackend, private data: DataService) {
    this.http = new HttpClient(handler);

    this.isLoading = true;

    this.loadCurrentAuthUser().then((res) => {
      this.currentAuthUser.subscribe((user) => {
        if (user && user.exists()) {
          // autorefresh token
          this.autorefreshToken();

          if (!this.currentUserSubject.value) {
            setTimeout(() => {
              this.loadCurrentUser()
                .then(() => {
                  this.isLoading = false;
                })
                .catch((e) => {
                  console.log('Error:', e);
                  this.isLoading = false;
                });
            }, 1500);
          } else {
            this.isLoading = false;
          }
        } else {
          if (this.currentUserSubject.value) {
            this.currentUserSubject.next(null);
            
          }
          this.isLoading = false;
        }
      });
    });
  }

  private loadCurrentAuthUser() {
    return new Promise((resolve, reject) => {
      let user = new AuthUser(JSON.parse(localStorage.getItem('bzcu')));

      this.currentAuthUserSubject = new BehaviorSubject<AuthUser>(user);
      this.currentAuthUser = this.currentAuthUserSubject.asObservable();

      this.currentUserSubject = new BehaviorSubject<User>(null);
      this.currentUser = this.currentUserSubject.asObservable();

      this.tokenRefreshedSubject = new BehaviorSubject<String>(null);
      this.tokenRefreshed = this.tokenRefreshedSubject.asObservable();

      const now = new Date();
      // revalidating token
      if (user.access_token && user.expires && user.expires <= now.getTime()) {
        this.refreshToken()
          .then((user) => {
            resolve(user);
          })
          .catch((err) => {
            //this.logout();
            resolve(null);
          });
      } else {
        this.tokenRefreshedSubject.next(user.access_token);
        resolve(user);
      }
    });
  }

  public updateUserStatus(newStatus: string) {
    let currentUser = this.getCurrentAuthUser();
    currentUser.status = newStatus;
    this.currentAuthUserSubject.next(currentUser);
  }

  public loginWithEmail(credentials) {
    const url = this.api_root + '/auth/login';

    return new Promise((resolve, reject) => {
      this.http
        .post(url, credentials)
        .toPromise()
        .then((res) => {
          let user = new AuthUser(res);
          // check the whitelist

          if (environment.auth.roles && environment.auth.roles.whitelist) {
            let winter = _.intersection(
              environment.auth.roles.whitelist,
              user.roles
            );
            if (winter.length == 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (environment.auth.roles && environment.auth.roles.blacklist) {
            let binter = _.intersection(
              environment.auth.roles.blacklist,
              user.roles
            );
            if (binter.length > 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (user.exists() && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('bzcu', JSON.stringify(res));
            this.currentAuthUserSubject.next(user);
            // this.tokenRefreshedSubject.next(user.access_token);
            this.authenticated = true;
            resolve(true);
          } else {
            reject({ error: 'authentication_error' });
          }
        })
        .catch((err) => {
          console.log('Error login', err);
          reject(err.error || 'Server error');
        });
    });
  }

  public registerWithEmail(credentials) {
    const url = this.api_root + '/auth/register';

    return new Promise((resolve, reject) => {
      this.http
        .post(url, credentials)
        .toPromise()
        .then((res) => {
          let user = new AuthUser(res);

          // check the whitelist

          if (environment.auth.roles && environment.auth.roles.whitelist) {
            let winter = _.intersection(
              environment.auth.roles.whitelist,
              user.roles
            );
            if (winter.length == 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (environment.auth.roles && environment.auth.roles.blacklist) {
            let binter = _.intersection(
              environment.auth.roles.blacklist,
              user.roles
            );
            if (binter.length > 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (user.exists() && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('bzcu', JSON.stringify(res));
            this.currentAuthUserSubject.next(user);
            // this.tokenRefreshedSubject.next(user.access_token);
            this.authenticated = true;
            resolve(true);
          } else {
            reject({ error: 'authentication_error' });
          }
        })
        .catch((err) => {
          console.log('Error login', err);
          reject(this.parseErrors(err));
        });
    });
  }

  public checkVerification(mode, token) {
    const url = this.api_root + '/auth/verification';

    return new Promise((resolve, reject) => {
      this.http
        .post(url, { mode: mode, token: token })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log('Error checkVerification', err);
          reject(this.parseErrors(err));
        });
    });
  }

  public forgotPassword(credentials) {
    const url = this.api_root + '/auth/forgotpassword';
    return new Promise((resolve, reject) => {
      this.http
        .post(url, credentials)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log('Error reset password', err);
          reject(err.error || 'Server error');
        });
    });
  }

  public resetPassword(data) {
    const url = this.api_root + `/auth/resetpassword`;
    return new Promise((resolve, reject) => {
      this.http
        .post(url, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log('Error reset password', err);
          reject(err.error || 'Server error');
        });
    });
  }

  public validateAccount(data) {
    const url = this.api_root + `/auth/validateaccount`;
    return new Promise((resolve, reject) => {
      this.http
        .post(url, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log('Error activate account', err);
          reject(err.error || 'Server error');
        });
    });
  }

  public logout(redirect = '/') {
    // remove user from local storage to log user out
    localStorage.removeItem('bzcu');
    this.authenticated = false;
    this.currentAuthUserSubject.next(null);
    if (this._tokenInterval) {
      clearInterval(this._tokenInterval);
    }
    if (redirect) {
      window.location.href = redirect;
    }
  }

  public getCurrentAuthUser(): AuthUser {
    return this.currentAuthUserSubject.value;
  }

  public refreshToken(): Promise<AuthUser> {
    const url = this.api_root + '/auth/refresh';
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.getCurrentAuthUser().access_token,
    });

    return new Promise((resolve, reject) => {
      this.http
        .post(url, {}, { headers: header })
        .toPromise()
        .then((res) => {
          let user = new AuthUser(res);
          if (
            user.exists() &&
            user.access_token &&
            user.roles &&
            user.roles.length > 0
          ) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('bzcu', JSON.stringify(user));
            this.currentAuthUserSubject.next(user);
            this.tokenRefreshedSubject.next(user.access_token);

            this.authenticated = true;
            resolve(user);
          } else {
            reject('error_refresh_token');
          }
        })
        .catch((err) => {
          console.log('Error refresh token', err);
          reject(err.error || 'Server error');
        });
    });
  }

  public getCurrentUser(): User {
    return this.currentUserSubject ? this.currentUserSubject.value : null;
  }

  public loadCurrentUser() {
    return new Promise((resolve, reject) => {
      this.data
        .getAsPromise('auth/me?ts=' + new Date().getTime())
        .then((res) => {
          if (res && res.data) {
            let user = new User(res.data);
            this.currentUserSubject.next(user);
            // ask permission for receiving notifications
            resolve(user);
          } else {
            reject('not_authenticated');
            this.logout();
          }
        })
        .catch((err) => {
          console.log('Error ', err);
          reject(err);
        });
    });
  }

  autorefreshToken() {
    if (!this._tokenInterval) {
      this._tokenInterval = setInterval(() => {
        if (this.currentAuthUserSubject.value) {
          const token = this.currentAuthUserSubject.value.access_token;

          const helper = new JwtHelperService();
          const expirationDate = helper.getTokenExpirationDate(token);
          const isExpired = helper.isTokenExpired(token);

          if (isExpired) {
            this.logout();
            return false;
          }
          let delta = moment(expirationDate).diff(moment().utc(), 'seconds');
          if (delta <= this._tokenIntervalDuration) {
            this.refreshToken();
          }
        }
      }, this._tokenIntervalDuration * 1000);
    }
  }

  parseErrors(data) {
    let ret = data.error;
    ret.fields = {};
    if (data.error.errors && data.error.errors.length > 0) {
      data.error.errors.forEach((err) => {
        ret.fields[err.field] = err.code;
      });
    }
    return ret;
  }

  getCurrentUserAsObservable(){
    return this.currentUser;
  }
}
