import { DataService } from './../../../services/data.service';
import { PusherService } from './../../../services/pusher.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'events-dialog',
  templateUrl: './events-dialog.component.html',
  styleUrls: ['./events-dialog.component.scss']
})
export class EventsDialog {

  constructor(
    public dialogRef: MatDialogRef<EventsDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    public pusher: PusherService,
    protected dataService: DataService,
    protected router:Router
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  download(event,item) {
    // console.log('DOWNLOAD', event);
    if (event) {
      if(item && item.type == 'file_download') {
        this.dataService.getFileFromEvent(event)
        .then(() => {
          // console.log('FILE DOWNLOADED', event)
          this.pusher.removeEvent(event);
          this.dialogRef.close({ event });
        })
      } else if(item && item.type == 'billing_build') {
        this.pusher.removeEvent(event);
        this.dialogRef.close({ event });
      } else {
        this.pusher.removeEvent(event);
        this.dialogRef.close({ event });
      }
    }
  }

  goTo(url) {
    console.log('GOTO', url)
    this.router.navigateByUrl(url);
    this.dialogRef.close();
  }
}
