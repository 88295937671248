import { FileServer } from '../../../services/fileserver.service';
import { User } from '../../../models/User';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() user : User = null;
  @Input() size : string = "sm";
  @Input() width : string = null;
  public size_px = 60;

  constructor(
    public fs : FileServer,
  ) { }

  ngOnInit() {

    const sizes={
      'xs' : 30,
      'sm' : 60,
      'ms' : 120,
      'lg' : 180,
      'xl' : 400
    };

    this.size_px = sizes[this.size] || this.width;
  }

}
