import { EditUserDialog } from '../components/dialogs/userdialog/user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authentication.service';
import { DataService } from './data.service';
import { AlertDialog } from '../components/dialogs/alertdialog/alert-dialog.component';


@Injectable({
  providedIn: "root"
})
export class NavigationService {
  public pageTitle = '';

  protected main_menu_auth = [
    { type: "item", label: "STATIC.NAV.HOME", path: "/dashboard", icon: "home", roles: ['dict','super-admin'] },
    { type: "separator"},
    { type: "item", label: "STATIC.NAV.DICTS", path: "/dicts", icon: "handyman", roles:['dict','super-admin']},
    { type: "item", label: "STATIC.NAV.DAPC", path: "/dapcs", icon: "assistant_direction", roles:['dict','super-admin']},
    { type: "item", label: "STATIC.NAV.DAPC_CONTACTS", path: "/dapc-contacts", icon: "contact_mail", roles:['dict','super-admin']},
    // {
    //   type: "group", label: "STATIC.NAV.DICT", icon: "folder", roles: ['dict','super-admin'],
    //   items: [
    //   ]
    // },   
    // { type: "separator"},
    // {
    //   type: "group", label: "STATIC.NAV.DAPC", icon: "folder", roles: ['dict','super-admin'],
    //   items: [
    //   ]
    // },   
    // { type: "separator"},
    {
      type: "group", label: "STATIC.NAV.SETTINGS", icon: "settings", roles: ['super-admin'],
      items: [
        { type: "item", label: "STATIC.NAV.EMAILS", path: "/emails", icon: "emails", roles:['dict','super-admin']},
        { type: "item", label: "STATIC.NAV.USERS", path: "/customers", icon: "people", roles: ['super-admin'] }
        //{ type: "item", label: "STATIC.NAV.PARAMETERS", path: "/parameters", icon: "settings", roles:['super-admin'] },
        //{ type: "item", label: "STATIC.NAV.LISTS", path: "/lists", icon: "lists", roles:['super-admin'] },
      ]
    }, 
    { type: "separator"},
  ];

  protected main_menu = [
    //  { type: "item", label: "NAV.LOGOUT", path: "/logout", icon: "exit_to_app" },
  ];


  protected profile_menu_auth = [
    { type: "item", label: "STATIC.NAV.PROFILE", icon: "account_circle", click: () => { this.showUserDialog() } },
  ];
  protected profile_menu = [
    //  { type: "item", label: "NAV.LOGOUT", path: "/logout", icon: "exit_to_app" },
  ];

  constructor(
    public auth: AuthenticationService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private dataService : DataService
  ) {
    const self = this;

  }

  getMenu(menu) {
    let user = this.auth.getCurrentAuthUser();

    switch (menu) {
      case 'main':
        return user && user.exists() ? this.main_menu_auth : this.main_menu;
        break
      case 'profile':
        return user && user.exists() ? this.profile_menu_auth : this.profile_menu;
        break

    }
  }

  logout() {
    this.auth.logout();
  }

  setTitle(title, subtitle = '') {
    if (title) {
      this.pageTitle = this.translate.instant(title);
      if (subtitle) {
        this.pageTitle += ' : ' + subtitle;
      }
    } else {
      this.pageTitle = '';
    }
  }

  getPageTitle() {
    return this.pageTitle;
  }

  showUserDialog() {
    let title = this.translate.instant('USER.MY_PROFILE');
    let user = this.auth.getCurrentUser();
    console.log("user = ", user)
    const dialogRef = this.dialog.open(EditUserDialog, {
      width: '540px',
      height: 'auto',
      autoFocus: false,
      disableClose: true,
      data: {
        title: title,
        item: user,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.auth.loadCurrentUser();
    });
  }

  

 
}
