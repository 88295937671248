import * as _ from 'lodash';

export class User {
  id: number = null;
  client_id: number = null;
  alias: string = null;
  birthdate: string = null;
  company: string = null;
  country_iso: string = null;
  disabled_at: string = null;
  endSubscriptionAt: string = null;
  is_deletable: boolean = null;
  mobile: number = null
  newsletter: boolean = null;
  notifications_email: number = null
  notifications_fcm: number = null
  numLogins: number = null
  origin: string = null;
  password: string = null;
  status: string = null;
  updated_at: string = null;
  avatar: string = null;
  firstname: string = null;
  lastname: string = null;
  email: string = null;
  gender: string = null;
  phone: string = null;
  created_at: string = null;
  email_verified_at: string = null;
  phone_verified_at: string = null;
  last_login: string = null;
  notification_hash: string = null;
  pr: string = null;
  roles: Array<string> = [];
  permissions: Array<string> = [];
  stripe_customer_id: string = null;
  address1: string = null;
  address2: string = null;
  zipcode: string = null;
  city: string = null;
  vat_number: string = null;

  constructor(id?: number | Object) {
    if (typeof id == 'object') {
      this.import(id);
    } else {
      this.id = id;
    }
  }

  exists() {
    return !!this.id;
  }

  import(data) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {

        switch (key) {
          case 'roles':
          case 'permissions':
            break;
          case 'pr':
            try {
              let pr = JSON.parse(atob(data[key]));
              this.roles = pr.roles;
              this.permissions = pr.permissions;
            } catch (e) {}
            break;
          default:
            this[key] = data[key];
        }
      }
    }
  }

  getIdentity(mode = 'alias') {
    return `${this.firstname} ${this.lastname}`;
  }

  hasRole(roles = []) {
    if (!this.roles || this.roles.length == 0) {
      return false;
    }
    return _.intersection(this.roles, roles).length > 0;
  }

  hasPermission(perms = []) {
    if (!this.permissions || this.permissions.length == 0) {
      return false;
    }
    return _.intersection(this.permissions, perms).length > 0;
  }
}
