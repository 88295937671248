export class DapcContact{

    id:number=null;
    firstname:string=null;
    lastname:string=null;
    service:string=null;
    address_1:string=null;
    address_2:string=null;
    zipcode:string=null;
    city:string=null;
    email:string=null;
    phone:string=null;
    fax:string=null;
    is_beuzit:string=null;

    constructor(id?: number | Object) {
        if (typeof (id) == 'object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    import(data) {
        for (var key in data) {
            if (this.hasOwnProperty(key)) {
                this[key] = data[key];
            }
        }
    }
}