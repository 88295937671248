import { MomentDateAdapter, MatMomentDateAdapterOptions} from "@angular/material-moment-adapter";
import { Moment } from "moment";


export class CustomDateAdapter extends MomentDateAdapter {
    dateFormat ="YYYY-MM-DD";

    constructor(dateLocale: string, _options?: MatMomentDateAdapterOptions | undefined) {
        super(dateLocale, _options);

        let s = localStorage.getItem("dateFormat");
        if (s) {
            this.dateFormat = s;
        }


    }

    parse(value: any, parseFormat: string | string[]): Moment | null {

        let dateFormat: string | string[] = this.dateFormat || parseFormat;

        // console.log('CustomDateAdapter parse format = ', dateFormat)
        return super.parse(value, dateFormat);
    }

    format(date: Moment, displayFormat: string): string {
        let dateFormat: string | string[] = this.dateFormat || displayFormat;
        return super.format(date, dateFormat);
    }

}