import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DictWork } from 'src/app/models/DictWork';
import { Email } from 'src/app/models/Email';
import { DataService } from 'src/app/services/data.service';
import { FileServer } from 'src/app/services/fileserver.service';

export interface AnswerData {
  'REDTDICT.elementsGeneraux.infoComplementaire': null;
  'REDTDICT.elementsGeneraux.categorieReseau1': null;
  'REDTDICT.elementsGeneraux.categorieReseau2': null;
  not_valid: null;
}

@Component({
  selector: 'completed-task-dialog',
  templateUrl: './email-details-dialog.component.html',
  styleUrls: ['./email-details-dialog.component.scss'],
})
export class EmailDetailsDialogComponent implements OnInit {
  public ready: boolean = false;
  public loader: boolean = false;
  public btn_save_loader = false;
  public btn_save_disabled = false;
  public email: Email = null;
  public form: FormGroup;
  public dict_work: DictWork = null;
  public checkboxes=[];
  public isOpenPanelBody=false;
  public isOpenPanel=true;

  constructor(
    public dialogRef: MatDialogRef<EmailDetailsDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    public fileServer: FileServer,
    private dataService: DataService
  ) {
    this.form = new FormGroup({
      'REDTDICT.elementsGeneraux.infoComplementaire': new FormControl(null),
      'REDTDICT.elementsGeneraux.categorieReseau1': new FormControl(null),
      'REDTDICT.elementsGeneraux.categorieReseau2': new FormControl(null),
      not_valid: new FormControl(null),
    },this.requireCheckedValidator());
    this.checkboxes = Array.from({ length: 4 }, () => (null));
  }

  ngOnInit() {

    if(this.data.item){
      this.email=this.data.item;
      this.ready=true;
    }

    if(this.data.dict_work){
      this.dict_work = this.data.dict_work;
      this.patchValues(); 
      if(this.data.dict_work?.dict_email_id){
        this.getEmail();   
      }
      else{
        this.ready=true;
      }   
    }     
  }

  getEmail(){
    this.ready=false;
    this.dataService
      .getAsPromise(`admin/emails/answer/${this.dict_work.dict_email_id}`)
      .then((result) => {
        if (result.data) {
          this.ready=true;
          this.email=result.data;   
        }
      })
      .catch((error) => {
        this.ready=true;
        console.log('Server error dict works', error);
      });
  }

  downloadFile(attachment, index: number) {
    this.fileServer
      .downloadFile(
        `admin/emails/${this.email?.id}/${index}`,
        attachment?.name,
        attachment?.mime_type
      )
      .then((res) => {});
  }

  onDownloadAttachments(){
    this.fileServer
    .downloadFile(
      `admin/emails/all/${this.email?.id}`,
    )
    .then((res) => {});
  }

  checkboxChange(index: number) {
    this.form.markAllAsTouched();
    this.checkboxes[index] = true;
    for(let i=0;i<4;i++){
      if(i != index){
        this.checkboxes[i]=false;
      }
    }
  }

  save() {

    this.form.markAllAsTouched();
    if (this.form.valid) {

      this.btn_save_disabled = true;
      this.btn_save_loader = true;
      let answer_data: AnswerData = this.form.value;

      this.dataService
        .putAsPromise(`admin/works/change_status/${this.dict_work.id}`, {
          data: answer_data,
        })
        .then((res) => {
          if (res && res.data) {
            this.dialogRef.close({ mode: 'update' });
          } else {
            this.dialogRef.close('bt_save');
          }
          this.btn_save_disabled = false;
          this.btn_save_loader = false;
        })
        .catch((err) => {
          this.btn_save_disabled = false;
          this.btn_save_loader = false;
          console.log('err', err);
        });

    }
  }

  patchValues() {
    switch (this.dict_work.status) {
      case 'concerned': {
        this.form.controls[
          'REDTDICT.elementsGeneraux.categorieReseau2'
        ].patchValue(true);
        this.checkboxes[2] = true;
        break;
      }
      case 'not_concern': {
        this.form.controls[
          'REDTDICT.elementsGeneraux.categorieReseau1'
        ].patchValue(true);
        this.checkboxes[1] = true;
        break;
      }
      case 'renew': {
        this.form.controls[
          'REDTDICT.elementsGeneraux.infoComplementaire'
        ].patchValue(true);
        this.checkboxes[0] = true;
        break;
      }
      case 'invalid': {
        this.form.controls['not_valid'].patchValue(true);
        this.checkboxes[3] = true;
        break;
      }

      default:
        break;
    }
  }

  requireCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate (formGroup: FormGroup) {
      let checked = 0;
  
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];
  
        if (control.value === true) {
          checked ++;
        }
      });
  
      if (checked < minRequired) {
        return {
          requireChecked: true,
        };
      }
  
      return null;
    };
  }
}
