import { DataService } from './../../../services/data.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, ElementRef, ViewChild, Input, HostBinding, Optional, Self, OnInit, DoCheck, Output } from '@angular/core';
import { FormControl, NgControl, FormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldControl } from '@angular/material/form-field';

export interface OptionColumns {
    id: string;        // column name used as id
    label: string;      // column name used as label
    operator?: string; // operator for filter(for where clause): equal: =, start: like .%, end: like %. , contain: like %..%
};
const defaultOptionColumns: OptionColumns = {
    id: 'id',
    label: 'name',
    operator: 'contain'
};

export interface OptionItem {
    id: number;
    label: string;
};

/**
 * @title Ajax Chips Autocomplete
 */

@Component({
    selector: 'ajax-autocomplete-chips',
    templateUrl: './ajax-autocomplete-chips.component.html',
    styleUrls: ['./ajax-autocomplete-chips.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: AjaxAutocompleteChipsInput },
    ],

})
export class AjaxAutocompleteChipsInput
    implements
    MatFormFieldControl<any[]>,
    ControlValueAccessor,
    OnInit,
    DoCheck {

    public ready = false;

    private sub: Subscription = null;
    public isLoading = false;
    public itemsList: any = null;
    public parametersData = {};
    public noOptionMsg: boolean = false;
    public prevFieldControlValue = null;
    public currentFieldControlValue = null;


    separatorKeysCodes: number[] = [ENTER, COMMA];
    stateChanges = new Subject<void>();
    static nextId = 0;
    private _placeholder: string;
    focused = false;
    errorState = true;
    controlType = 'ajax-autocomplete-chips';

    fieldCtrl = new FormControl();
    filteredItemsList: OptionItem[];
    selectedItems: OptionItem[] = [];

    _value: any[] = [];
    _populated = false;

    @Input()
    multiple: boolean = true;
    @Input()
    color: string = null;
    @Input()
    editable: boolean = false;
    @Input()
    selectable: boolean = true;
    @Input()
    removable: boolean = true;
    @Input()
    readonly: boolean = false;
    @Input()
    visible: boolean = true;
    @Input()
    addOnBlur: boolean = true;
    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get value(): any[] | null {
        return this._value;
    }
    set value(val: any[] | null) {
        this.initValue(val);
        this.stateChanges.next();
        this.onChange(val);
        this.onTouch();

    }

    @Input()
    set endpoint(endpoint: string) {
        this._endpoint = endpoint;
    }
    _endpoint: string = null;

    @Input() set extraParameters(extraParameters) {
        this._extraParameters = extraParameters;
    }
    get extraParameters() {
        return this._extraParameters;
    }
    private _extraParameters = {};

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;

    get empty() {
        return !this._value || this._value.length == 0;
    }
    @Input()

    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.fieldCtrl.disable() : this.fieldCtrl.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @HostBinding() id = `ajax-autocomplete-chips-${AjaxAutocompleteChipsInput.nextId++}`;

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @HostBinding('attr.aria-describedby') describedBy = '';

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    @Input() click_event;

    @Input() set OptionsColumns(columns: OptionColumns) {
        if (columns) {
            this._OptionsColumns = columns;
        }
    };
    _OptionsColumns: OptionColumns = defaultOptionColumns;

    @Input() numberOfOptions = null;
    @Input() withAddNewOption = false;
    @Input() objectOption = false;
    @Input() searchMinLength = 2;
    @Input() searchTimeout = 300;
    @Input() oneRequest = false;
    public requestNb = 0;

    @Output() onClear: EventEmitter<any> = new EventEmitter();
    @Output() onValueChanges: EventEmitter<any> = new EventEmitter();

    @ViewChild('ajaxChipInput', { static: false }) chipInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild('auto', { static: false, read: MatAutocompleteTrigger }) matAutocompleteTrigger: MatAutocompleteTrigger;
    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
    constructor(
        private dataService: DataService,
        @Optional() @Self() public ngControl: NgControl,
        fb: FormBuilder,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>,
    ) {


        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
        // mark the input as touched just after the input was focused
        if(this.focused) {
            this.onTouch();
        }
        this.focused = !!origin;
        this.stateChanges.next();
        });

        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit() {
        this.sub = this.fieldCtrl.valueChanges
            .pipe(
                debounceTime(this.searchTimeout),
                tap(() => {
                    // this.itemsList = [];
                    // this.isLoading = true;
                }),
                switchMap(value => {
                    this.onValueChanges.emit(value);
                    let canSearch = false;
                    if(!value || !this.searchMinLength || (this.searchMinLength && value.length >= this.searchMinLength)) {
                        canSearch = true;
                    }
                    
                    // console.log("VALUE === ", value)
                    if (!value) {
                        // this.selectedItem = null; 
                        this.updateValue();
                    }
                    if(canSearch) {
                        // this.itemsList = [];
                        this.isLoading = true;

                        let params = {
                            id: this._OptionsColumns.id,
                            label: this._OptionsColumns.label
                        };
                        if (this.numberOfOptions) {
                            params['per_page'] = this.numberOfOptions;
                        }

                        if (value) {
                            let selectedValue = value.label ? value.label.trim().toLowerCase() : value;
                            let f = {
                                operator: this._OptionsColumns.operator,
                                value: selectedValue
                            };
                            params['filter'] = JSON.stringify(f);
                        }

                        if(this._extraParameters) {
                            params['parameters'] = JSON.stringify(this._extraParameters);
                        }

                        this.parametersData = {
                            params: params
                        };
                        // console.log(this.parametersData)
                        if(!this.oneRequest || this.requestNb == 0) {
                            this.itemsList = [];
                            return this.dataService.getAsPromise(`admin/lists/${this._endpoint}`, this.parametersData);
                        } else {
                            return this._localFilter(value)
                        }
                    } else {
                        return [];
                    }
                })
            )
            .subscribe(data => {
                // console.log("valueChanges")
                if (data && data['data']) {
                    this.itemsList = data['data'];
                    if (!this.itemsList.length) {
                        this.noOptionMsg = true
                    } else {
                        this.noOptionMsg = false
                    }
                }
                if(!this.oneRequest || this.requestNb == 0) {
                    this._filter();
                }
                this.isLoading = false;
                this.requestNb ++;

                this.ready = true;
            }
        );

        this.loadField();
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);

        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    onContainerClick(event: MouseEvent) {
        // this.fieldCtrl.setValue(null);

        if (!this.readonly && (event.target as Element).tagName.toLowerCase() != 'input') {
            this.elRef.nativeElement.querySelector('input').focus();
        }
    }

    onChange = (value: any[]) => { };

    onTouch = () => { };

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(value: any[]): void {
        // console.log("WRITEVALUE = ",value);
        this.initValue(value);

        if (this.chipInput) {
            this.chipInput.nativeElement.blur()
        }
    }

    initValue(value: any[]): void {
        if (!value) {
            value = [];
        } else if (typeof value == 'string' || typeof value == 'number') {
            value = [value];
        } else if (typeof (value.length) == 'undefined') {
            value = [value];
        }
        this._value = value;

        this.populateSelected();
    }

    // Allows Angular to register a function to call when the model (rating) changes.
    // Save the function as a property to call later here.
    registerOnChange(fn: (value: any[]) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {

    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.ngControl.touched;
            this.stateChanges.next();
        }
    }

    updateValue() {
        let selectedItems = [];
         if(this.withAddNewOption || this.objectOption) {
            selectedItems = this.selectedItems ? this.selectedItems : [];
         } else {
            selectedItems = this.selectedItems ? _.map(this.selectedItems, (o) => o.id) : [];
         }
         this.writeValue(selectedItems);
         this.onChange(this._value);
         //this.onTouch();
    }

    private populateSelected() {
        if (this._value) {
            this._populated = true;
            let selectedItems = [];

            this.selectedItems.forEach(si => {
                let option = si;
                if(si.id && si.label) {
                    option = {
                        id: si.id,
                        label: si.label
                    }
                }
                
                selectedItems.push(option);
            })
            
            this.selectedItems = selectedItems;
           
            // console.log("populateSelected = ",this.selectedItems);
        }
    }

    add(event: MatChipInputEvent): void {
        // Add only when MatAutocomplete is not open
        // To make sure this does not conflict with OptionSelected Event
        // if (this.withAddNewOption && !this.matAutocomplete.isOpen) {
        if (this.withAddNewOption && this.noOptionMsg) {
            const input = event.input;
            const value = event.value;
            // Add our fruit
            if (this.editable && (value || '').trim()) {
                let o = _.find(this.selectedItems, { label: value });

                if(!o) {
                    let n: OptionItem = {
                        id: null,
                        label: value.trim()
                    };
                    if (this.multiple) {
                        this.selectedItems.push(n);
                    } else {
                        this.selectedItems = [n];
                    }
                }
            }

            // Reset the input value
            if (input) {
                input.value = '';
                this.fieldCtrl.setValue(null);
            }
            this.autocomplete.closePanel();
            this.updateValue();
        }
    }

    @Output() onRemove: EventEmitter<any> = new EventEmitter();
    remove(item: OptionItem): void {
        _.remove(this.selectedItems, (o) => {
            // if the item is a new item added onBlur: this.add()
            if(item.id==null) {
                return o.label == item.label
            } else {
                return o.id == item.id
            }
        });
        this.updateValue();

        this.onRemove.emit(item)
    }

    @Output() onSelect: EventEmitter<any> = new EventEmitter();
    selected(event: MatAutocompleteSelectedEvent): void {
        // check if value not yet there
        // console.log(event.option.value)

        let o = _.find(this.selectedItems, { id: event.option.value.id });
        if (!o) {
            if (this.multiple) {
                this.selectedItems.push(event.option.value);
            } else {
                this.selectedItems = [event.option.value];
            }
        }

        this.fieldCtrl.setValue(null);
        this.chipInput.nativeElement.value = '';

        this.updateValue();

        if (this.click_event) {
            this.click(event.option, event.option.value)
        }

        this.onSelect.emit(event.option.value);
    }

    private _filter(value: any = null): OptionItem[] {
        this.filteredItemsList = this.itemsList;
        let ret = this.itemsList
        return ret;
    }

    private _localFilter(label: any): any[] {
        let ret = [];
        if (!label || (label && typeof label != 'string') ) {
            ret =  _.filter(this.itemsList,  (o)=> {
                return o;
            });
        } else {
            let operator = this._OptionsColumns.operator
            const filterValue = label.toLowerCase();
            ret = this.filter(operator, filterValue);
        }

        this.filteredItemsList = ret;
        if (!ret.length) {
            this.noOptionMsg = true;
        } else {
            this.noOptionMsg = false;
        }

        this.isLoading = false;
        return ret;
    }

    filter(operator, filterValue) {
        let ret = [];
        switch(operator) {
            case 'equal':
                ret =  _.filter(this.itemsList, (o)=> {
                    return o.label.toLowerCase() === filterValue;
                });
                break;
            case 'start':
                ret =  _.filter(this.itemsList, (o)=> {
                    return o.label.toLowerCase().indexOf(filterValue) === 0;
                    // return o.label.toLowerCase().startsWith(filterValue);
                });
                break;
            case 'end':
                ret =  _.filter(this.itemsList, (o)=> {
                    return o.label.toLowerCase().endsWith(filterValue);
                });
                break;
            case 'contain' :
                ret =  _.filter(this.itemsList, (o)=> {
                    return o.label.toLowerCase().indexOf(filterValue) > -1;
                });
                break;
            default:
                ret =  _.filter(this.itemsList, (o)=> {
                    return o.label.toLowerCase().indexOf(filterValue) > -1;
                });
                break;
        }
        return ret;
    }

    keyPress(event) {
        if (!this.editable || !this.ready) {
            event.preventDefault();
        }
    }

    keyUp(event) {
    }

    @Output() onChipClick: EventEmitter<any> = new EventEmitter();

    click(event, item) {
        if (this.click_event) {
            this.onChipClick.emit({ event: event, item: item });
        }
    }

    clearControl() {
        this.fieldCtrl.setValue(null);
        this.elRef.nativeElement.querySelector('input').value = null;
        this.onClear.emit(true);
    }

    displayFn(option: OptionItem) {
        // console.log("@@@@@@@@@ = ",option)
        return option && option.label ? option.label : '';
    }

    loadField() {
        let proms = [];
        this.selectedItems = [];
        // populate the input if _value
        if(this._value) {
            let ids = [];
            this._value.forEach(v => {
                let option = v;
                if(v.id && v.label) {
                    option = {
                        id: v.id,
                        label: v.label
                    }
                    this.selectedItems.push(option);
                } else {
                    ids.push(v);
                }
            })

            // if input format is [id1,...], make first request for getting the values
            if(ids.length) {
                let params = {
                    id: this._OptionsColumns.id,
                    label: this._OptionsColumns.label
                };
                if (this.numberOfOptions) {
                    params['per_page'] = this.numberOfOptions;
                }

                if(this._extraParameters) {
                    params['parameters'] = JSON.stringify(this._extraParameters);
                }
                params['ids'] = JSON.stringify(ids);

                this.parametersData = {
                    params: params
                };
                proms.push(
                    this.dataService.getAsPromise(`admin/lists/${this._endpoint}`, this.parametersData).then(res => {
                        if(res && res.data) {
                            let selectedItems = [];
                            res.data.forEach(element => {
                                selectedItems.push({
                                    id: element.id,
                                    label: element.label
                                })
                            });
                            this.selectedItems = selectedItems;
                        }
                    })
                )
            }
        } else {
            this.selectedItems = [];
        }

        Promise.all(proms).then(res => {
            this.fieldCtrl.setValue(null);
        })
    }
}
