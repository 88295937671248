import * as _ from 'lodash';

export class AuthUser {
  access_token: string = null;
  expires_in: number = null;
  token_type: string = null;
  roles: Array<string> = [];
  permissions: Array<string> = [];
  pr: string = null;
  expires: number = null;
  status: string = null;
  refresh_ttl = null;

  constructor(data: Object = null) {
    if (data) {
      this.import(data);
    }
  }

  exists() {
    return !!this.access_token;
  }

  import(data) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        switch (key) {
          case 'roles':
          case 'permissions':
            break;
          case 'pr':
            this.pr = data[key];
            try {
              let pr = JSON.parse(atob(data[key]));
              this.roles = pr.roles;
              this.permissions = pr.permissions;
            } catch (e) {}
            break;
          case 'refresh_ttl':
            if (!data['expires']) {
              let t = new Date().getTime() + (data[key] - 30) * 1000;
              this.expires = t;
            }
            break;
          default:
            this[key] = data[key];
        }
      }
    }
  }

  hasRole(roles = []) {
    if (!this.roles || this.roles.length == 0) {
      return false;
    }
    return _.intersection(this.roles, roles).length > 0;
  }
}
