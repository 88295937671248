import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'resendwork-dialog',
  templateUrl: './resendwork-dialog.component.html',
  styleUrls: ['./resendwork-dialog.component.scss']
})
export class ResendWorkDialog  {
  
  constructor(
    public dialogRef: MatDialogRef<ResendWorkDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
 

}
