
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { CustomDateAdapter } from 'src/app/adapters/customdate.adapter';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import { DataService } from 'src/app/services/data.service';
import { ValueList } from 'src/app/models/ValueList';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DapcContact } from 'src/app/models/DapcContact';

@Component({
  selector: 'app-dapc-contact-dialog',
  templateUrl: './dapc-contact-dialog.component.html',
  styleUrls: ['./dapc-contact-dialog.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: DapcContactDialog },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DapcContactDialog implements OnInit {
  public btn_save_disabled = false;
  public btn_save_loader = false;
  public form: FormGroup;
  public type: ValueList = null;
  public dapc_contact: DapcContact = null;


  constructor(
    public dialogRef: MatDialogRef<DapcContactDialog>,
    public auth: AuthenticationService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    // create formgroup
    this.form = new FormGroup(
      {
        firstname: new FormControl('', [Validators.required]),
        lastname: new FormControl('', [Validators.required]),
        service: new FormControl(''),
        address_1: new FormControl('', [Validators.required]),
        address_2: new FormControl(null),
        zipcode: new FormControl('', [Validators.required]),
        city: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required,Validators.email]),
        phone: new FormControl('', [Validators.required]),
        fax: new FormControl(null),
        is_beuzit:new FormControl(this.data.item ? this.data.item?.is_beuzit : "0")
      },
      {
        updateOn: 'change',
      }
    );
  }

  ngOnInit(): void {

    if (this.data && this.data.item) {     
      this.dapc_contact = new DapcContact(this.data.item);
      this.form.patchValue(this.dapc_contact);
    }

    if(this.data?.from_dapc){
      this.form.get('is_beuzit').disable();
    }

    

  }

  save() {
    this.form.markAllAsTouched();
    if (this.dapc_contact) {
      this.update(this.dapc_contact);
    } else {
      this.create();
    }
  }

  create() {

    if (this.form.valid) {
      this.btn_save_disabled = true;
      this.btn_save_loader = true;
      let data = this.form.value;

      this.dataService.postAsPromise('admin/dapc_contacts', data).then(res => {
        if (res && res.data) {
          this.dialogRef.close(new DapcContact(res.data));
        } else {
          this.dialogRef.close('bt_save');
        }
        this.btn_save_disabled = false;
        this.btn_save_loader = false;
      }).catch(err => {
        this.btn_save_disabled = false;
        this.btn_save_loader = false;
        console.log('err', err);
      });
    } else {
      console.log("Form not valid");
    }
  }

  update(item) {
    if (this.form.valid) {
      this.btn_save_disabled = true;
      this.btn_save_loader = true;

      let data = this.form.value;
      this.dataService.putAsPromise(`admin/dapc_contacts/${item.id}`, data).then(res => {
        if (res && res.data) {
          this.dapc_contact.import(res.data);
          this.dialogRef.close({ mode: 'update' });
        } else {
          this.dialogRef.close('bt_save');
        }
        this.btn_save_disabled = false;
        this.btn_save_loader = false;
      }).catch(err => {
        this.btn_save_disabled = false;
        this.btn_save_loader = false;
        console.log('err', err);
      });
    } else {
      console.log("Form not valid");
    }
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

}
